<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt-5">
        <form action="" @submit.prevent="handleSubmitForm">
          <div class="alert alert-success" v-if="postStatus === 'success'">
            Zmiany zapisane poprawnie
          </div>
          <div class="alert alert-danger" v-if="errorMessage">
            {{ errorMessage }}
          </div>
          <div class="row">
            <div class="col-md-6">
              <card>
                <div slot="header" class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Podstawowe parametry</h3>
                  </div>
                </div>
                <base-input
                  class="mb-3 "
                  type="text"
                  label="Nazwa kodu rabatowego"
                  placeholder="Wpisz nazwę kuponu"
                  v-model="name"
                />
                <base-input
                  class="mb-3 "
                  type="text"
                  label="Kod rabatowy"
                  placeholder="Wpisz kod rabatowy"
                  v-model="couponCode"
                />
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      class="mb-3 "
                      type="text"
                      label="Minimalna kwota zamówienia"
                      placeholder="Wprowadź minimalną kwotę"
                      v-model="minGrossPrice"
                    />
                  </div>
                  <div class="col-md-6">
                    <base-input
                      class="mb-3 "
                      type="text"
                      label="Rabat procentowy"
                      placeholder="Wprowadź rabat procentowy"
                      v-model="discount"
                    />
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-6">
              <card>
                <div slot="header">
                  <h3 class="mb-0">Dodatkowe parametry</h3>
                </div>
                <div class="row mb-4">
                  <div class="col-6">
                    <h5>Czy aktywny?</h5>
                    <base-switch
                      on-text="Tak"
                      off-text="Nie"
                      v-model="isActive"
                    ></base-switch>
                  </div>
                  <div class="col-6">
                    <h5>Czy darmowa dostawa?</h5>
                    <base-switch
                      on-text="Tak"
                      off-text="Nie"
                      v-model="freeShipping"
                    ></base-switch>
                  </div>
                </div>
                <base-input
                  class="mb-3 "
                  type="text"
                  label="Ilość kuponów"
                  placeholder="Wprowadź ilość kuponów"
                  v-model="qty"
                />
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      class="mb-3 "
                      type="text"
                      label="Data od"
                      placeholder="YYYY-MM-DD HH:MM"
                    >
                      <Datepicker v-model="dateFrom" />
                    </base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input
                      class="mb-3 "
                      type="text"
                      label="Data do"
                      placeholder="YYYY-MM-DD HH:MM"
                    >
                      <Datepicker v-model="dateTo" />
                    </base-input>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <div class="text-right">
            <button class="btn btn-primary" type="submit" role="button">
              Submit form
            </button>
          </div>
        </form>
      </div>
  </div>
</template>

<script>
import Datepicker from "@/components/Datepicker/index.vue";

import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: 'CouponView',
  components: {
    BreadcrumbHeader,
    Datepicker
  },
  computed: {
    ...mapState('CouponAddModule', ['couponInformation', 'errorMessage', 'postStatus']),
    couponCode: {
      get() {
        return this.couponInformation.code
      },
      set(newVal){
        this.updateCouponField({
          field: 'code',
          value: newVal
        })
      }
    },
    minGrossPrice: {
      get() {
        return this.couponInformation.minGrossPrice / 100
      },
      set(newVal){
        this.updateCouponField({
          field: 'minGrossPrice',
          value: JSON.stringify(newVal * 100)
        })
      }
    },
    name: {
      get() {
        return this.couponInformation.name
      },
      set(newVal){
        this.updateCouponField({
          field: 'name',
          value: newVal
        })
      }
    },
    qty: {
      get() {
        return this.couponInformation.qty
      },
      set(newVal){
        this.updateCouponField({
          field: 'qty',
          value: newVal
        })
      }
    },
    discount: {
      get() {
        return this.couponInformation.discount / 100
      },
      set(newVal){
        this.updateCouponField({
          field: 'discount',
          value: +(newVal * 100)
        })
      }
    },
    isActive: {
      get() {
        return this.couponInformation.active
      },
      set(newVal){
        this.updateCouponField({
          field: 'active',
          value: newVal
        })
      }
    },
    dateFrom: {
      get() {
        return new Date(this.couponInformation.dateFrom)
      },
      set(newVal){
        this.updateCouponField({
          field: 'dateFrom',
          value: newVal
        })
      }
    },
    dateTo: {
      get() {
        return new Date(this.couponInformation.dateTo)
      },
      set(newVal){
        this.updateCouponField({
          field: 'dateTo',
          value: newVal
        })
      }
    },
    freeShipping: {
      get() {
        return this.couponInformation.freeShipping
      },
      set(newVal){
        this.updateCouponField({
          field: 'freeShipping',
          value: newVal
        })
      }
    },
    breadcrumb() {
      return [
        {title: 'Promocje', link: {name: 'coupons'}},
        {title: 'Kody promocyjne', link: {name: 'coupon-list'}},
        {title: `Kupon ID: ${this.$route.params.id}`}
      ];
    },
  },
  methods: {
    ...mapMutations('CouponAddModule', ['updateCouponField']),
    ...mapActions('CouponAddModule', ['submitForm', 'fetchCouponInformation']),
    handleSubmitForm(){
      const method = this.$route.params.id ? 'put' : 'post';

      try {
        this.submitForm({
          id: this.$route.params.id ?? '',
          method
        })
        this.formStatus = 'success'
      } catch (e) {
        console.log('dupa')
      }
    }
  },
  async mounted() {
    await this.fetchCouponInformation(this.$route.params.id)
  }
};
</script>
